@import '../shared/shared.styl'
@import './Dashboard.styl'

.content_wrapper
    &:not(:last-child)
        margin-bottom: 1rem

textLineHeight = 24px

.content_text
    @extend .body1
    margin-bottom: 4px
    line-height: textLineHeight
    display: -webkit-box
    -webkit-box-orient: vertical

.content_tag
    @extend .caption
    display: inline-block
    position: relative
    background: colors.gray300
    left: block-padding * -1
    padding: 0 8px
    margin-bottom: 1rem

.tag_news
    background: colors.purple300

.tag_recommendation
    background: colors.yellow300

.content_image
    margin: block-padding * -1
    margin-top: 0
    width: "calc(100% + %s)" % (block-padding * 2)
    height: auto