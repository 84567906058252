@import "../shared.styl"

.icon
    height: 48px
    width: 48px
    margin-bottom: 12px

.dragger_container
    display: flex
    flex-direction: column
    align-items: center
    padding: 10px

.browse_text
    color: colors.primary
    font-weight: bold

.guideline_text
    @extend .caption
    white-space: pre-line
    color: colors.gray800

.dragger_main
    background-color: colors.gray100 !important