@import '../../../shared/shared.styl'

.modal_reduced_header
	padding: 0

.sample_tooltip_step
	display: flex
	> img
		height: 120px

.tooltip_info
	margin-left: 5px