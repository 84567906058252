@import "../shared.styl"

.overlay
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: colors.overlay_actual
  opacity: 0
  pointer-events: none

.button
  position: relative
  cursor: pointer

.button:hover .overlay
  opacity: 0.25
