@import '../Button.styl'
@import '../../shared/shared.styl'

.file_listing_container
	padding: 12px 24px
	border: 1px solid colors.gray300
	border-radius: 2px
	.file_card_container
		height: 24px
		display: flex
		align-items: center
		justify-content: space-between
		.file_name_container
			max-width: calc(100% - 24px)
			display: flex
			align-items: center
			.file_name
				word-break: break-word

.byte
	@extend .body2
	margin-left: 16px
	color: colors.gray800

.trash_button
	@extend .center
	color: white
	padding: 0

.custom_curve_input_container
	margin-top: 10px
	> div + div
		margin-top: 10px

.custom_curve_input_row
	column-gap: 24px
	> div
		flex-grow: 1
		width: calc((100% - 24px)/2)

.transducer_row
	margin-top: 4px !important

.custom_curve_rectangular
	display: flex
	justify-content: space-between
	gap: 24px
	> div
		flex-grow: 1
		max-width: calc((100% - 24px)/2)

.guideline_text
	@extend .caption
	white-space: pre-line
	color: colors.gray800

.view_guidelines_button
	@extend .caption
	padding: 0

.mandatory_asterisk_color
	color: colors.red600

.mandatory_asterisk_field
	@extend .mandatory_asterisk_color
	margin-left: 5px

.required_information
	@extend .subtitle2
	font-size: 12px
	margin-top: 10px
	color: colors.gray800
	.mandatory_asterisk_color
		margin-right: 5px