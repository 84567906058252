@import '../../shared/shared.styl'

.license_wrapper
    position: relative
    margin: 24px 0

.license_item
    display: flex
    height: 60px
    border-radius: 4px
    background: colors.gray200
    align-items: center
    animation: fade-in 200ms
    &:not(:last-child)
        margin-bottom: 1px

    .icon_wrapper
        padding: 10px 24px
        display: flex

.license_item_free
    @extend .license_item
    background: colors.gray100
    border: 1px solid colors.gray300

.license_group_container
    margin: 32px 0 16px 0

.license_group_title
    @extend .display3
    margin: 8px 0px

.action_button
    flex-grow: 1
    text-align: right

.activate_now_button
    @extend .caption
    padding: 0
    font-weight: normal
    height: auto
    display: flex
    align-items: baseline

.free_trial_activate_now_container
    @extend .caption
    display: flex
    align-items: center
    height: 40px
    gap: 3px

.right_container
    flex-grow: 1
    display: flex
    justify-content: flex-end

.expiration_text
    margin: auto
    margin-right: 80px

.license_button_container
    display: flex
    align-items: center
    justify-content: flex-end
    min-width: 250px

.expand_icon
    display: flex
    margin-right: 26px
    cursor: pointer
    svg,img
        transition: transform 200ms

.tier_features_container
    background-color: colors.gray100
    display: flex
    justify-content: center
    gap: 32px
    padding: 32px
    > div
        min-width: 100px
        max-width: 290px
        flex: 1 1 auto

.tier_features_container_modal
    @extend .tier_features_container
    background-color: unset
    padding: 0

.plan_list div
    font-size: 14px

.features_container
    border-radius: 2px
    width: 280px
    flex: 1 1 auto
    > div
        margin: 16px

.tier_container
    display: flex
    flex-direction: column
    gap: 16px

.tier_title
    @extend .display2

.features_container_selected
    @extend .features_container
    border: 1px solid colors.primary

.free_trial_upgrade_button
    display: flex
    align-items: flex-end
    flex-grow: 1

.features_container_normal
    @extend .features_container
    border: 1px solid colors.gray300