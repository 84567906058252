@import "../../components/shared/shared.styl"

.measurement_container
    padding: 24px
    width: 1px
    flex-grow: 1
    overflow: auto
    display: flex
    flex-direction: column
    align-items: center
    @media print
        overflow: unset

.header
    display: flex
    align-items: center
    line-height: 24px
    margin-bottom: 1rem

    >h1
        margin: auto
        flex: 1
        width: 1px
        overflow: hidden
        text-overflow: ellipsis
        padding: 2px 0

    .measurement_title_container
        flex: 1

.export_info
    text-align: right
    display: flex
    flex-direction column
    align-items: flex-end

.export_button
    margin-top: 15px