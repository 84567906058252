@import '../shared/shared.styl'

.export_options_wrapper
    display: block


.export_item
    display: flex
    flex-direction: column
    align-items: flex-start
    &:not(:last-child)
        margin-bottom: 1em

.item_title
    @extend .subtitle2
    margin-bottom: 4px

.item_select
    min-width: 240px
