@import '../shared/shared.styl'

block-padding = 24px

.dashboard_container
    @extend .app_layout_content
    padding: 4px 12px
    overflow: auto

.column
    display: flex
    flex-direction: column
    height: fit-content

.column_wide
    @extend .column
    width: 1px
    flex-grow: 1

.column_narrow
    @extend .column
    max-height: calc(100% - 24px)
    overflow: auto
    margin: 12px
    flex-shrink: 0
    flex-grow: 0
    width: calc(3 / 12 * 100%)

.column_narrow > div
    margin: 24px 0 0 0

.column_narrow > div:first-child
    margin: 0

.dashboard_block
    margin: 12px
    background: colors.gray100
    border-radius: 4px
    padding: block-padding
    animation: fade-in 200ms
    overflow: hidden

.dashboard_block_header
    display: flex
    justify-content: space-between
