.column_search
    display: flex
    flex-wrap: wrap
    justify-content: flex-end
    width: 250px
    padding: 0.25rem

    input, button
        margin: 0.25rem
        height: 40px

    input
        width: 100%

.range_input
    width: 100%
    display: flex
    align-items: center
    input
        appearance: none
