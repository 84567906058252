@import '../../../../shared/shared.styl'

.extraInfo_container
	padding: 0 16px
	&:not(:last-child)
		margin-bottom: 8px

.category_properties
	@extend .subtitle1
	color: white
	margin-bottom: 8px
	@media print
		color: black

.properties_wrapper
	display: flex
	flex-wrap: wrap

.property_container
	display: flex
	flex-direction: column
	margin-right: 40px
	margin-bottom: 24px

.property_title
	@extend .caption

.property_value
	@extend .body2
	color: white
	@media print
		color: black