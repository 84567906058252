@import "../../shared/shared.styl"

.root
  @extend .page_root
  flex-direction: column
  width: 100%
  height: 100%

.container_body
  @extends .center;
  flex-grow: 1
  svg
    width: 64px

.iframe_container
  height: 100%
  width: 1px
  flex: 1

.loading_video
  width: 100%
  position: absolute
  height: 100%
  background-color: black

.loading_image
  @extends .center, .loading_video
  > svg
    width: 70%

.tool_error_text
  text-align: center

.tool_error_contact
  @extend .subtitle1
  padding: 0