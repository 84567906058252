h1,
h2,
h3,
h4,
h5 {
  color: inherit;
}
.display2 {
  font-size: 24px;
  line-height: 36px;
  margin: 0;
}
.display3 {
  font-size: 20px;
  line-height: 32px;
}
.header2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
}
.subtitle1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
}
.subtitle2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
}
.subtitle3 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  margin: 0;
}
.body1 {
  font-size: 16px;
  line-height: 24px;
}
.body2 {
  font-size: 14px;
  line-height: 24px;
}
.caption {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}
.caption2 {
  font-size: 12px;
  line-height: 24px;
}
.page_root {
  width: 1px;
  flex-grow: 1;
  display: flex;
}
.menu_item {
  padding-left: 25px;
  padding-right: 25px;
}
.hidden {
  display: none;
}
.app_layout_content {
  width: 1px;
  flex-grow: 1;
  display: flex;
}
.block_shadow {
  box-shadow: 0 1px 5px rgba(0,0,0,0.15);
}
[data-mode] .modal_title,
.modal_title {
  font-size: 20px;
  line-height: 24px;
}
[data-mode] button.button_style,
.button_style {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.25px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*# sourceMappingURL=src/components/PermissionDenied/PermissionDenied.css.map */