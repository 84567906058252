@import '../../../shared/shared.styl'

.data-view-collapse
    border: none

    .ant-collapse-content
        border-color: colors.gray100
    
    .ant-collapse-item
        border-color: colors.gray100
        font-size: 16px
        line-height: 24px


$border-style = 1px solid var(--border-color)
$border-radius = 4px
$border-radius-top = $border-radius $border-radius 0 0
$border-radius-bottom = 0 0 $border-radius $border-radius

.data-view-html
    position relative
    width: 100%
    @media print
        width: 19cm
        color: black

.data-view-panel-html
    position: relative
    max-width: 100%
    margin-bottom: 2rem
    --border-color: colors.gray300

    @media print
        color: black
        --border-color: black
        break-inside: avoid

    .ant-table-title
        background: colors.gray100
        border-color: var(--border-color) !important
        border-top: $border-style
        border-left: $border-style
        border-right: $border-style
        border-radius: $border-radius-top
        @media print
            color: black

    .ant-table-container
        border-color: var(--border-color) !important
        border-top: $border-style
        border-left: $border-style
        border-radius: $border-radius-bottom


    .ant-table-thead > tr > th
    .ant-table-tbody > tr > td
        border-color: var(--border-color) !important
        @media print
            color: black

    .ant-table-thead > tr > th:last-child
    .ant-table-tbody > tr > td:last-child
        border-right: $border-style

    .ant-table-tbody > tr:last-child > td
        &:first-child
            border-bottom-left-radius: $border-radius            
        &:last-child
            border-bottom-right-radius: $border-radius

    .ant-table-cell-scrollbar
        box-shadow: 0 1px 0 1px colors.gray100

// replace directly above when adopted for rest of the products
#newHTMLFormat .data-view-panel-html
    margin-bottom: 20px