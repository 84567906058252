.card_image
    flex-shrink: 0
    flex-grow: 0
    position: relative
    height: 80px
    width: 88px
    border-radius: 10px
    background-color: white
    margin-bottom: 8px
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden

    img
        position relative
        left: 0
        top: 0
        width: 100%
        height: 100%
        object-fit: contain