@import '../AppFormStyles.styl'

.button_area
    position: relative
    text-align: right

[data-mode] button.submit_button
    @extend .button_style
    padding: 0 10px
    margin-left: 10px

.change_password
    margin-left: 5px
    text-decoration: underline
    cursor: pointer

.company_logo_placeholder
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    background: colors.cover_black_color
    background-image: linear-gradient(colors.off_grey, colors.overlay_color)
    border-radius: 4px
    display: flex
    justify-content: center
    align-items: center
