@import '../../../../src/components/shared/Upload/UploadDragger.styl'

.input_container
    > div + div
        margin-top: 24px

.input_row
    column-gap: 24px
    > div
        flex-grow: 1
        width: calc((100% - 24px)/2)

.shapefile_text
    margin-top: 24px

.epsg_container
    :global .ant-form-item-control-input
        min-height: unset

:global #spatialDataInputs .ant-form-item
    margin-bottom: 0