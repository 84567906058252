@import '../shared.styl'

.header
    width: 100%
    height: 56px
    display: flex
    justify-content: space-between
    align-items: center
    padding: 8px 24px
    @media print
        width: 19cm
        padding: 8px 0

.side_panel_header
    @extend .header
    height: auto

.logo_container
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 12px

.tag
    background-color: colors.yellow700
    color: black
    padding: 0px 16px
    border-radius: 10px
    white-space: break-spaces

.logo
    display: flex
    color: white
    cursor: pointer
    @media print
        color: black

.actions
    @extend .center

.support_link
    @extend .center
    color: white !important
    > svg
        margin-right: 5px

.tools_dropdown
    @extend .center
    color: white !important
    svg
        width: 16px
        margin-left: 5px

.insights_container
    display: flex

.keyboard_input
    width: 1px
    height: 1px
    visibility: hidden
    opacity: 0