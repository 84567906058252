@import "../../shared/shared.styl"

.form_title
    display: flex
    align-items: center
    color: colors.primary
    svg
        margin-left: 10px
        height: 30px
        width: auto

.form_item
    padding-bottom: 4px
    &:last-child
        padding-bottom: 0    

[data-mode] .form_submit_button,
.form_submit_button
    margin-left: 12px
    min-width: 120px
    text-transform: uppercase
    svg
        margin-right: 5px

.form_block
    pointer-events: none