@import '../../shared/shared.styl'

.styled-modal-wrapper
    height: 100%
    overflow: hidden
    padding: 40px

// place modal in the center
.styled-modal
    > div:has(.ant-modal-content)
        display: flex
        height: 100%
        align-items: center

.styled-modal
    top: initial
    bottom: initial
    padding: 0
    height: 100%
    width: 100%

    .ant-modal-close
        top: 12px
        inset-inline-end: 15px

    .ant-modal-content
        display: flex
        flex-direction: column
        width: 100%
        max-height: 100%

    .ant-modal-header
        position: relative
        padding: 18px 24px
        border-bottom: none
        box-shadow: 0 1px 0 colors.gray300

        flex-shrink: 0
        h3
            @extends .display2
            line-height: 28px

    .ant-modal-body
        padding: 24px
        overflow: hidden
        display: flex
        flex-direction: column

    .ant-modal-footer
        padding: 12px 24px
        border: none
        box-shadow: 0 -1px 0 colors.gray300
        margin-top: 0
