@import '../shared/shared.styl'

.container
	@extend .center
	margin: auto
	height: 100%

.content_container
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	padding: 30px
	background: colors.gray100
	max-width: 680px
	min-width: 500px
	gap: 25px
	white-space: pre-line
	text-align: center

.logo
	width: 80px
	height: 80px
	border-radius: 10px

.launch_button
	width: 100%