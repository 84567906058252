@import '../shared/shared.styl'

.recent_list
    position: relative
    margin-top: 8px
    width: 100%
    display: flex
    flex-direction: column
    overflow: auto
    animation fade-in 200ms

.empty_state
    min-height: 180px
    display: flex
    flex-direction: column
    justify-content:  center
    align-items: center

.item_action
    flex-basis: 50px
    flex-grow: 0
    display: flex
    align-items: flex-start
    justify-content: space-between

    > *:not(:last-child)
        margin-right: 24px

.button
    display: flex
    align-items: center
    padding: 8px
    border: 1px solid transparent;
    font-weight: 500;

.button_export
    position: relative
    padding-right: 2rem
    @extend .button
    &::before
        display: none !important
    > [role="img"],
    > svg
        position: absolute
        right: 8px
        top: 0
        bottom: 0
        margin: auto

.icon_wrapper
    display: flex

.table
    :global
        .ant-table,
        tr,
        .ant-table-tbody > td,
        .ant-table-thead > tr > th
            background-color: transparent

        .ant-table-body
            overflow:visible !important

        .ant-table-tbody > tr
            cursor: pointer

        .ant-table-tbody > tr > td
            border: none
        
        .ant-table-thead > tr > th
            border: none
            height: 56px
            font-weight: 500