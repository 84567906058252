@import '../shared/shared.styl'

.container
  @extends .page_root
  flex-direction: column
  height: 100%
  width: 100%

.content_container
  @extends .center
  height: 1px
  flex: 1
  width: 100%

.block_container
  @extends .center, .subtitle1
  background-color: colors.gray100
  padding: 48px
  width: 620px
  max-width: calc(100% - 20px)
  flex-direction: column

.block_container > button:first-of-type
  margin-top: 48px

.block_container > button:not(:first-of-type)
  margin-top: 20px

.block_title
  @extends .display2
  margin-bottom: 16px
  white-space: pre-line
  text-align: center

.footer_link
  padding-left: 5px