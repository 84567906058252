@import '../../../shared/shared.styl'

#logbookModal 
    overflow: auto
    max-height: 100%

    .title-wrapper
        display: flex
        justify-content: space-between
        align-items: center
        padding-top: 2rem
        margin-bottom 1rem
        h1
            margin: 0
        button
            @media print
                display: none

    b
        font-weight: 700

    .content-container 
        padding-top: 7px
        padding-bottom: 3px

    .chat-body
        padding-top: 0px

    li
        list-style: none

    .initial-image
        margin-right: 20px
        display: inline-block
        vertical-align: top
        img
            display: block
            width: auto
            height: 120px
            border: 0px
            object-fit: cover

    .initial-content
        display: inline-block
        vertical-align: top

    .message-color
        position: absolute
        top: 2px
        height: 12px
        width: 12px
        z-index: 1000
        left: -23px
    .full-container
        background-color: colors.gray300
        padding: 20px
    .full-container.date-container
        text-align: center
        background-color: #f4f3f3

    .message-line
        float: left
        position: absolute
        top: 0px
        height: 100%
        width: 2px
        z-index: 90
        left: -18px
        background-color: #c3c3c3
    .chat-body
        height: 670px
        padding-left: 20px
        padding-right: 20px
        margin-top: -20px
    img.online
        display: none
    .message-text
        margin-left: 0px
        width: 100%
        display: inline-block
        vertical-align: top

        padding: 0
        line-height: normal
    .username
        pointer-events: none
    .attachment_audio_thumbnail_div
        margin: 5px
    .message-buttons
        width: 100%
        text-align: right
        margin-top: 5px
    .attachment_audio_thumbnail_div img
        position: relative
        display: block !important
        border: 0px
    .message-title
        font-size: 12px
        color: black
        padding-right: 20px
        font-weight: 600
    .message-action
        font-size: 10px
        color: black
        padding-right: 10px
        font-weight: 600
    .intro-container pre
        max-width: 100%
    pre
        max-width: 80%
        word-break: normal
        word-wrap: normal
        white-space: pre-line

        border: none
        margin: 0px
        padding: 0px
        font-family: 'Open Sans', Arial, Helvetica, Sans-Serif
        font-size: 13px
        background-color: transparent

    li.message .message-text time
        float: right
        position: relative
        margin-top: 5px

    .message.messageWithAttachment time
        position: absolute !important 

    .message span.username
        color: #3276b1
        font-weight: 700
        font-size: 10px
        text-decoration: none !important
        margin-left: 10px

    li.message .content-container
        margin-left: 35px

    li.message .message-text time
        position: absolute
        top: 1px
        right: 0
        font-size: 10px
        line-height: 10px
        color: #afafaf

    ul
        margin: 0
        padding: 0

    video::-internal-media-controls-download-button,
    audio::-internal-media-controls-download-button
        display: none

    video::-webkit-media-controls-enclosure,
    audio::-webkit-media-controls-enclosure
        overflow: hidden

    video::-webkit-media-controls-panel,
    audio::-webkit-media-controls-panel
        width: calc(100% + 30px) /* Adjust as needed */
