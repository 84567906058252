@import '../../shared/shared.styl'

.title_container
	@extend .header2
	padding-top: 20px
	display: flex
	justify-content: space-between
	align-items: center

.dropdown
	@extend .center, .subtitle1
	border-radius: 2px
	border: 1px solid colors.gray300
	padding: 4px 8px 4px 16px
	color: colors.gray300

.dropdown_arrow
	margin-left: 14px

.action_container
	display: flex
	flex-direction: column
	gap: 1px
	margin-top: 20px
	> div
		display: flex
		justify-content: space-between
		align-items: center
		height: 60px
		background-color: colors.gray100
		border-radius: 2px
		padding: 0 16px

.password_container
	width: 474px
	margin-top: 13px

.password_button_area
	display: flex
	justify-content: flex-end
	gap: 15px

.password_form_item
	margin-bottom: 16px