@import '../shared/shared.styl'

.notification_list
  height: 100%
  overflow: auto
  color: colors.white

.header
  @extends .display3
  display: flex
  align-items: center
  justify-content: space-between
  padding: 8px 8px 8px 24px

.item_container
  display: flex
  width: 100%
  padding: 8px
  align-items: flex-start

.item_content
  @extends .body2
  display: flex
  flex: 1
  flex-direction: column
  padding-left: 16px

.item_header
  @extends .subtitle1
  word-break: break-word
  display: flex
  justify-content: space-between
  flex: 1

  >span
    @extends .caption
    white-space: nowrap
    margin-left: 8px

.item_message
  width: 100%
  word-break: break-word

.unread_notification
  border-radius: 50%
  background-color: colors.primary
  width: 8px
  height: 8px
  margin-top: 16px
  margin-right: 8px

.read_notification
  @extends .unread_notification
  background-color: transparent

.empty_data
  @extends .center
  height: 100%

.highlight
  color: colors.primary