@import '../../shared/shared.styl'

.table_header
	font-weight: 500 // copy from antd

.main_header, .sub_header, .file_name
	font-weight: bold

.toc_item
	list-style-type: decimal
	font-size: 16px

.toc_card
	padding: 0 24px // make the width alrger to account for the list-style-type

.column
	width: 230px

.utilities_line
	color: #00B8D9
	