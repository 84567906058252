@import '../../../../shared/shared.styl'

.slide
    .image_wrapper
        position: relative
        width: calc(100% - 20px)
        padding-top: calc((100% - 20px) * 0.6)
        margin: 10px
        img
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            object-fit: cover

.active_slide
    .image_wrapper
        box-shadow: 0 0 0 2px colors.primary

.slider_wrapper
    width: 100%;
    max-width: 800px;
    margin: 0 auto 32px;

    .current_snapshot
        position: relative
        width: 100%
        padding-top: 60%
        margin-bottom: 8px
        img
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            object-fit: contain
            object-position: center bottom

        &:hover .snapshot_information
            visibility: visible
            opacity: 1

        .snapshot_information
            display: flex
            justify-content: space-between
            visibility: hidden
            opacity: 0
            width: 100%
            position: absolute
            bottom: 0
            min-height: 80px
            padding: 16px 32px
            background: rgba(colors.dark_background, 0.7)
            color: white
            transition: opacity 300ms, visibility 300ms

        .actions
            display: flex
            justify-content: center
            align-content: center
            a
                margin: auto
