@import '../../shared/shared.styl'

.modal_container
  height: initial

.export_options_wrapper
  display: block

.item_select
  width: 100%

.activate_license_button
  float: right

.form_item
  width: 100%

.form_item
  :global
    .ant-form-item-extra
      margin-top: 6px
      font-size: 12px
      line-height: 20px
      color: colors.gray800
    .ant-form-item-explain-error
      margin-top: 6px
      font-size: 12px

.license_key_form_item
  @extend .form_item
  margin-bottom: 0

.form_item_last
  margin-bottom: 0

.error_message
  @extend .caption
  button
    @extend .caption
    height: 100%
    font-weight: normal
    padding: 0
    margin: 0

.confirm_license_button svg
  margin-right: 5px