@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import './components/shared/shared.styl'

html,
body
    margin: 0;
    font-family: fontFamily
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    height: 100%
    width: 100%
    overflow: hidden
    background-color: black
    font-size: 14px
    line-height: 1.5715
    color: colors.white
    @media print
        height: auto
        overflow: visible

code
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

#root
    height: 100%
    width: 100%
    overflow: auto
    @media print
        height: auto
        overflow: visible


// For detecting none-touch screen
@media(hover: hover)
    *
        scrollbar-width: thin
    ::-webkit-scrollbar
        width: 6px
        height: 6px
    ::-webkit-scrollbar-corner
        background: transparent
    ::-webkit-scrollbar-track
        background: transparent
    ::-webkit-scrollbar-thumb
        border-radius: 20px
        background-color: transparent
        opacity: 0
    :hover::-webkit-scrollbar-thumb
        background-color: colors.gray500
        opacity: 1

.ant-checkbox-inner
.ant-input,
.ant-input-number-input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector
    background: colors.dark_background

.ant-select:not(.ant-select-customize-input) .ant-select-selector
    box-shadow: none !important

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title
    padding: 9px 12px

.ant-modal-content
    padding: 0 !important
.ant-modal-header
    margin-bottom: 0 !important

.ant-btn[disabled]
    background: transparent
    color: colors.gray500

.ant-btn-background-ghost:disabled
    border-color: colors.grey !important

.ant-form-item-explain.ant-form-item-explain-error
    color: colors.red600

.ant-btn-background-ghost.ant-btn-dangerous
    color: colors.red600
    border-color: colors.red600
    &:hover
        color: colors.red700
        border-color: colors.red700

.ant-btn-background-ghost.ant-btn-disabled, .ant-btn-background-ghost[disabled]
    background-color: transparent !important
    color: colors.grey !important
    border-color: colors.grey !important
    &:hover
        color: colors.off_grey !important
        border-color: colors.off_grey !important

.ant-btn-dangerous.ant-btn-link
    color: colors.red600
    &:hover
        color: colors.red700

.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input
    background-color: colors.black !important
    border-color: colors.red600 !important

.ant-input-number-status-error
    border-color: colors.red600 !important

.ant-message-success .anticon
    color: colors.green600

.ant-tooltip-inner
    color: colors.white !important
    display: flex
    align-items: center
    box-shadow: 0 0 0 1px colors.gray300 !important

.ant-tooltip-arrow
    &::after
        box-shadow: 0 0 0 1px colors.gray300 !important

[data-mode='archive']
    .ant-select:not(.ant-select-customize-input) .ant-select-selector
        background: colors.gray200

[role='alert'] .ant-form-item-explain-error
    color: colors.red600

// radio buttons
.ant-radio-button-wrapper-checked
    color: black !important
.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked)
    background-color: black
    color: white
.ant-radio-button-wrapper
    font-weight: 500

// for button with type 'link'
a.ant-btn-link
    text-decoration: none
    height: unset !important
    padding: 0 !important

.ant-pagination-options
    :not(.ant-select-focused) .ant-select-selector
        border-color: colors.light_grey !important

table .ant-empty-description
    color: white !important

// antd doesnt allow setting background specifically for table's empty state
.ant-table-cell:has(.ant-empty)
    background: colors.gray200

// radio button
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner
    background-color: transparent

.ant-radio-wrapper .ant-radio-inner::after
    background-color: colors.primary