@import '../../shared/shared.styl'

.move_button svg
  margin-right: 5px

.add_folder
  display: flex
  align-items: center
  > svg
    margin-right: 10px

.folder_container
  height: 276px
  background: colors.dark_background
  border: 1px solid colors.gray300
  border-radius: 4px
  overflow: scroll

.folder_cell
  height: 56px
  border-bottom: 1px solid colors.gray300
  display: flex
  align-items: center
  cursor: pointer
  .check_outline
    @extend .center
    min-width: 50px
  .ellipses_text
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

