@import './Button.styl'
@import '../shared/shared.styl'

.modal_content_wrapper
    @extend .body1

.duration_selection
    @extend .body1
    width: 120px

.text_area
    @extend .body1
    margin: 1rem 0
    padding: 1rem
    background: colors.gray100
    color: colors.teal600
    border-radius: 4px
    border-color: transparent

.delete_icon
    @extend .icon
    width: 16px

.delete_icon_only
    @extend .icon
    svg
        width: 24px
        height: auto
