@import './Button.styl'

.active
    svg
        animation: spin linear 1000ms infinite
        
    

@keyframes spin {
    from {
        transform: rotate(360deg)
    }

    to {
        transform: rotate(0deg)
    }
}