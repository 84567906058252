@import '../../shared/shared.styl'

.app_wrapper
    width: 100%;
    height: 100%;
    display: flex
    flex-direction: column

[data-mode='dark'] {
    background: colors.dark_background
}