@import "../../shared/shared.styl"

.menu_item
  display: flex
  align-items: center
  justify-content: flex-start
  padding: 0

.tick
  @extend .center
  width: 20px
  margin-right: 5px

.button
  @extend .center
  border: 0
  padding: 0
  margin-right: 24px
  border-radius: 2px
  height: 24px
  width: 24px
  &:hover
    background: colors.hover_grey