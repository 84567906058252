@import '../../../shared/shared.styl'

.profile_delete_title
    font-weight: 500
    font-size: 18px
    line-height: 36px

.request_button
    margin: 16px 0

.disabled_container
    color: colors.grey