@import '../../../shared/shared.styl'

.footer_container
	display: flex
	justify-content: space-between
	.right_footer_container
		display: flex
		gap: 8px
		.download_curve
			display: flex
			align-items: center
			> svg
				width: 16px
				margin-left: 5px

.body
	background: colors.gray100
	padding: 30px
	display: flex
	flex-direction: column
	gap: 31px
	overflow: auto

	.image_container
		background-color: white

	img
		display: block
		margin: auto
		max-width: 100%
		max-height: 412px

	.caption_header
		color: colors.gray800
		font-size: 12px;
		line-height: 20px;

	.form_fields
		display: flex
		gap: 10px 40px
		font-weight: 400;
		margin-top: 8px
		flex-wrap: wrap
		.field_value
			font-size: 14px;
			line-height: 24px;

	.spinner
		display: block