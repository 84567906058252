@import '../shared.styl'

.expand_icon
    padding: 6px
    margin: -6px 0
    height: 24px
    width: 24px
    box-sizing: content-box
    transition: transform 200ms
    cursor: pointer

.flip
    transform: scaleY(-1)