@import '../shared/shared.styl'

.profile_root
    @extend .page_root


.menu_column
    width: calc(3 / 12 * 100%)
    flex-grow: 0
    flex-shrink: 0
    background: colors.gray100
    overflow: auto
    position: relative

.menu_list_container
    position: absolute
    top: 115px
    width: 100%

.profile_column
    width:1px
    flex-grow: 1
    overflow: auto

.profile_content
    padding: 0 24px 24px
