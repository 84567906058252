@import "../../shared/shared.styl"

profileImageSize = 120px

.profile_image_wrapper
    flex-shrink: 0
    position: relative 
    width: profileImageSize
    height: profileImageSize
    margin-right: 3rem
    &:hover
        .remove_button
            display: block

.edit_avatar_button
    @extend .body1
    background: colors.gray200
    color: white
    position absolute
    width: 100%;
    height: 28px
    bottom: 0
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer

.edit_avatar
    position absolute
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden
    
.edit_avatar_round
    @extend .edit_avatar
    border-radius: 50%

.edit_avatar_rect
    @extend .edit_avatar
    border-radius: 4px

.remove_button
    position: absolute
    top: 100%
    display: none
    text-align center
    width: 100%
    color: red 
    text-transform: lowercase
    cursor pointer
    font-size: 12px

.full_profile_image
    img
        object-fit: contain !important