@import '../shared.styl'

.menu_item
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    font-size: fonts.lg
    line-height 24px
    height: 40px;
    padding: 8px 24px
    cursor: pointer
    transition: background 200ms
    &:hover
        background: colors.hover_grey

.menu_item:hover .hover_hide
    display: none

.menu_item_active
    background: colors.gray300

.ellipses_button
    color: white !important
    display: flex
    align-items: center
    padding-right: 0
