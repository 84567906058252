@import '../../../../shared/shared.styl'

.button_absolute
    position: absolute
    padding: 0
    top: 50%
    transform: translateY(-50%)

.right_button
    @extend .button_absolute
    right: -50px

.left_button
    @extend .button_absolute
    left: -50px

.snapshot
    max-width: 100%
    max-height: 100%
    object-fit: contain