@import '../shared.styl'

.profile_menu_item
  display: flex
  > a
    color: white

.env_tag
  padding: 9px 25px
  color: colors.gray500

.dropdown_menu
  > li
    padding: 0