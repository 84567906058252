@import '../shared/shared.styl'

.container_html_viewer
    display: flex;
    flex-direction: column;
    height: 100%

.container_body_html_viewer
    @extends .center;
    height: 1px;
    flex-grow: 1;
    > div:first-child
        height: 100%

.dataView_header
    padding: 24px 24px 0
    display: flex
    justify-content: space-between
    align-items: flex-end

.common_actions
    display: flex
    > button
        height: 40px
    > *:not(:last-child)
        margin-right: 1rem

.table_row
    td
        background: rgba(255, 255, 255, 0.1)
        border-width: 0 !important
        margin-bottom: 3px !important
        &:first-of-type
            border-radius: 4px 0 0 4px
        &:last-of-type
            border-radius: 0 4px 4px 0

.dataView_content_html_viewer
    padding: 24px
    height: 1px
    flex-grow: 1

.dataView_content
    padding: 24px
    padding-top: 32px
    height: 1px
    flex-grow: 1
    overflow: auto

.folder_title
    @extends .caption
    color: colors.gray500
    margin-left: 24px

.archive_system_folder_title
    @extend .folder_title
    margin-top: 8px

.menu_title_content
    display: flex
    align-items: center
    flex-grow: 1
    width: 100%
    svg,img 
        flex-shrink: 0
        margin-right 8px
        width: 24px
    span
        width: 1px
        flex-grow: 1
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis

.menu_container
    flex-grow: 0
    flex-shrink: 0
    width: calc(25% - 48px)
    border-right: 1px solid colors.gray300
    padding-top: 24px
    overflow: auto
    display: flex
    flex-direction: column
    justify-content: space-between

.menu_column
    overflow: auto

.add_folder
    background: colors.gray200
    color: colors.primary
    > button
        height: 40px
        color: inherit
        width: 100%
        text-align: left
        border: 0
        display: flex
        align-items: center
        justify-content: flex-start
        &:hover, &:focus
            color: inherit !important

.delete_folder_modal
    width: 568px !important
    .delete_folder_info
        white-space: pre-line

.content_column
    @extend .app_layout_content
    flex-direction: column
    position: relative

.content_column_html_viewer
    @extend .content_column
    height: 100%
    width: 100%

.divider
    margin: 8px 12px
    border: 0px solid colors.gray300
    border-bottom-width: 1px


.table_wrapper
    width: 100%
    height: 100%

.action_cell
    padding: 0 8px !important

    .action_icon
        height: 48px
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        &:first-child
            padding-left: 0
        svg,img
            transition: transform 200ms
    
    .action_wrapper
        display: grid
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center

        button
            justify-content: flex-start
            flex-grow: 1

.title_wrapper
    display: flex
    align-items: center

    >div
        width: 1px
        flex: 1
        display: flex
        align-items: center
        gap: 8px

    span
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

.title_flag
    flex-shrink: 0
    color: colors.primary
    display: inline-block
    margin: -2px 2px -2px 0

.title_flag_clickable
    cursor: pointer

.result_tag
    @extend .caption
    display: inline-block
    padding: 0 16px
    border-radius: 16px
    background-color: alertColor-information
    color: colors.gray100
    &.passed
        background-color: alertColor-success
    &.failed
        background-color: alertColor-danger

.dataView_pagination
    :global
        .ant-select-item-option
            vertical-align: middle
            &:before
                content: ""
                display: inline-block
                height: 6px
                width: 10px
                margin: auto
                margin-right: 0.5rem
                border: 1.5px solid transparent
                border-top: 0
                border-right: 0
                transform: rotate(-45deg)

        .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
            background: inherit
            position relative
            &:before                
                border-color: inherit
        .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector
            height: 26px
        .ant-pagination-options
            > div.ant-select
                display: flex
                align-items: center
            .ant-select-item-option-selected
                color: white
            .ant-select-selection-search-input
                height: auto !important

.folder_sort_container
    display: flex
    align-items: center
    justify-content: space-between

.archive_background_color
    background-color: colors.gray200

[data-mode='archive']
    @extend .archive_background_color

.archive_menu_container
    @extend .archive_background_color, .menu_container
    padding-top: 0
    justify-content: flex-start

.active_padding
    padding-top: 24px

.archive_container
    background-color: colors.dark_background
    padding-top: 24px
    padding-bottom: 8px

.archive_mode_back
    height: auto
    margin: 0 20px
    span
        padding: 0 5px !important
        white-space: normal
        text-align: left

.archive_mode_header
    @extend .subtitle3
    margin: 0 24px

.incomplete_sync
    @extend .caption, .center
    color: colors.gray800

.name_expand_icon
    @extend .center
    cursor: pointer
    color: colors.grey
    svg,img
        transition: transform 200ms

.danger_dropdown_item
    color: colors.red600 !important

.dropdown_divider
    margin: 4px 8px
    background-color: colors.gray300 !important