@import "../../shared/shared.styl"

.input, .input input
    svg
        color: white

[data-mode="bright"]
    .input, .input input
        svg
            color: colors.light_grey
