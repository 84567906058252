@import '../../../shared/shared.styl'

.full_container
    background-color: colors.gray300
    padding: 20px

.date_container
    display: inline-block
    background-color: colors.gray500
    padding: 5px 10px
    color: white
    border-radius: 2px
    margin-top: 10px

    &:first-child
        margin-top: 0
    
    @media print
        color: currentColor
        font-weight bold

.content_container
    position: relative
    padding-top: 7px
    padding-bottom: 3px
    // Fix for Firefox scollbar covering itme on the right
    @supports (-moz-appearance:none)
        padding-right: 1rem

.message
    page-break-inside: avoid
    .icon
        position: absolute
        top: 8px
        border-radius: 4px
        padding: 2px
        height: 20px
        width: 20px
        display: flex
        justify-content: center
        align-items: center
        background: transparent

        &.icon_with_logo
            background: white

        .blue
            color: #3A8B89

        .green
            color: #ABD040

        .red
            color: #D0021C

        .grey
            color: #C3C3C3

        .icon_log
            color: #12908D

        .icon_log_delete
            color: #CA1818

.user, .time
    display: inline-block
    color: #AFAFAF

.time
    margin-left: 32px

.user
    float: right;

.log_event
    display: none;

    &.highlighted
        display: block;

    @media print
        display: block;
