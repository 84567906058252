@import '../../../shared/shared.styl'

.json-inspector,
.json-inspector__selection
    display: flex
    flex-direction: column
    max-height: 100%

.json-inspector__not-found,
.json-inspector__leaf_root
    height: 1px
    overflow: auto
    min-height: 400px

.json-inspector__key
    color: colors.gray500

.json-inspector__line
    overflow: hidden

.json-inspector__value_helper,
.json-inspector__value_null,
.json-inspector__not-found
    color: white;

.json-inspector__value_string
    color: colors.primary;

.json-inspector__value_number
    color: colors.purple600;

.json-inspector__hl
    background: colors.gray300;
    box-shadow: 0 -1px 0 2px colors.gray300;

.json-inspector__toolbar
    margin-bottom: 1rem
    input
        font-family: fontFamily
        color: white

.json-viewer-wrapper
    position: relative
    max-height: 100%
    .ant-tabs
        max-height: 100%
    .ant-tabs-tab
        border-bottom: 1px solid white
    
    .ant-tabs-nav-list
        width: 100%
        .ant-tabs-tab
            @extend .subtitle1
            padding: 4px
            width: 100%
            margin: 0
            justify-content: center

    .ant-tabs-content-holder
        max-height: 100%
        display: flex

    .ant-tabs-content
        max-height: 100%

    .ant-tabs-tabpane
        max-height: 100%
        textarea
            height: auto !important
            min-height: 500px
            max-height: 100% !important