@import '../../shared/shared.styl'

.body_container
    display: flex
    flex-direction: column
    align-items: center
    gap: 15px
    text-align: center

.logo
    width: 80px
    height: 80px
    border-radius: 10px

.launch_button
    width: 100%

.download_button
    @extend .caption
    margin-left: 5px
    height: auto