@import '../../../../shared/shared.styl'

.message_container
	position: relative
	outline: none

.highlighted, .highlightable:hover
	background-color: colors.gray300
	cursor: pointer

.info_icon
	position: absolute
	top: 2px
	right: 2px