@import '../shared/shared.styl'

.container
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:100%
    background: colors.gray100
    max-width: 680px;
    max-height: 445px
    border-radius: 4px
    padding: 40px

.content_container
    flex-direction: column
    @extend .center
    display: flex
    justify-content: center
    width: 100%

.content_wrapper
    margin-top: 30px
    max-width: 524px;
    border-radius: 4px