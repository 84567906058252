@import '../../../shared/shared.styl'

.extraInfo_wrapper
	width: 100%
	display: flex
	flex-direction: column

.container_measurement
	text-align: left;

.value_measurement
	display: inline-block;
	min-width: 40px;
	text-align: right;
	padding-left: 10px;

.index_measurement
	display: inline-block;

.container_allmeasurements
	max-height: 350px;
	overflow-y: auto;
	padding-bottom: 10px;

.bar_tooltip
	background: colors.gray300

.fdl_graph_wrapper
	display: flex
	flex-direction: column
	position relative
	margin: 16px

.equotip_graph_wrapper
	display: flex
	flex-direction: column
	position relative
	margin: 16px

.custom_material_graph_wrapper
	max-width: 800px
	margin: 16px auto 0

@media print
	.highcharts_instance
		> div
			max-width: 100%
			height: fit-content !important
		svg
			max-width: 100%
			height: auto

.glm_readings_table
	margin-top: 32px
	:global
		.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table
			> tbody > tr > td,
			> thead > tr > th
				background: colors.gray200
				border-color: colors.gray100
				@media print
					color: black
