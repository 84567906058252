.notification_drawer

  .ant-drawer-close
    position: unset
    width: 32px
    height: 32px

  .ant-drawer-title
    font-size: 20px
    display: flex
    align-items: center