@import '../shared/shared.styl'

.app_root
    height: 100%
    width: 100%
    background: colors.dark_background
    color: white
    overflow: hidden
    display: flex
    min-width: 1024px
    @media print
        min-width: 100%
