@import "./shared.styl"

.slider
    position: relative
    width: calc(100% - 80px);
    margin: 0 40px;

    :global
        .slick-prev
            transform: translate(0, -50%) rotate(90deg)
            left: -45px
        .slick-next
            transform: translate(0, -50%) rotate(-90deg)
            right: -45px
        .slick-prev,
        .slick-next
            color: colors.primary
        .slick-disabled
            color: colors.teal400
        .slick-next::before,
        .slick-prev::before
            display: none


:global .slick-initialized .slick-track
    display: flex
    align-items: stretch
    > div.slick-slide
        flex: 1
        height: auto
        > div
            height: 100%
