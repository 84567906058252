@import "../shared/shared.styl"

.modal_container
  height: initial

.modal_content_wrapper
  @extend .body1
  margin: auto

.iframe_container
  height: 100%
  width: 1px
  flex: 1

.iframe_modal_container
  height: 100%
  width: 100%
  opacity: 0

.iframe_modal_container_loaded
  @extend .iframe_modal_container
  opacity: 1

.activate_license_button svg
  margin-right: 5px