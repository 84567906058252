@import '../../../../shared/shared.styl'

.slide
    height: 100%
    .image_wrapper
        position: relative
        margin: 10px
        height: calc(100% - 20px)
        min-height: 100px
        background-color: colors.gray100
        border: 1px solid colors.gray300
        border-radius: 4px
        img
            width: 100%
            height: 100%
            object-fit: contain
            padding: 1px
        .snapshot_information_container
            visibility: hidden
            opacity: 0
            z-index: 1
            background: rgba(colors.dark_background, 0.7)
            position: absolute
            bottom: 0
            border-bottom-left-radius: 5px
            border-bottom-right-radius: 5px
            width: 100%

        &:hover .snapshot_information_container
            visibility: visible
            opacity: 1

.snapshot_information
    display: flex
    justify-content: space-between
    width: 100%
    padding: 10px
    button
        padding: 0

.shortlist_button_container
    display: flex
    align-items: center
    justify-content: space-between

.HTML_export_image
    @extend .slide
    height: unset
    max-height: 300px

.HTML_export_container
    display: flex
    flex-wrap: wrap