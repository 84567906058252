spacing = 10px

.attachment_container
	padding: spacing
	border-radius: 4px
	box-sizing: border-box
	margin: spacing 0
	width: fit-content
	display: flex
	flex-wrap: wrap
	align-items: center

.image_container
	padding-bottom: spacing
	cursor: zoom-in
	flex-direction: column
	align-items: flex-start

.default_cursor
	cursor: default

.image_placeholder_container
	@extend .default_cursor
	flex-direction: column
	align-items: flex-start
	> img
		max-width: 20%

.image
	max-width: 100%

.full_image
	cursor: zoom-out

.filename
	width: 70%
	float: left
	text-align: left

.loading
	display: block
	margin 0 auto

.audio_download
	vertical-align: top

.audio_download,
.photo_download
	@media print
		display: none