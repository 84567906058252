@import '../shared.styl'

.root
    @extend .page_root
    flex-direction: column

.body
    height: 1px
    flex-grow: 1
    display: flex
    @media print
        height: fit-content
