@import '../shared/shared.styl'

.container
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%
  height:100%
  background: colors.gray100
  max-width: 680px;
  max-height: 445px
  border-radius: 4px
  padding: 40px

.content_container
  flex-direction: column
  @extend .center
  width: 100%
  height: 1px;
  flex-grow: 1;

.content_wrapper
  margin-top: 75px
  width: 100%;
  border-radius: 4px
  display: flex;
  flex-direction: column;
  align-items: stretch;

.body
  @extend .body1
  margin-bottom: 12px

.form > div:first-child span:first-child
  background-color: #121212

.form button
  flex-grow: 1
  margin-left: 0