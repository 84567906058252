@import '../shared/shared.styl'

.card_wrapper
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    width: 187px
    padding: 1rem 0
    border-radius: 2px
    &:hover
        background: colors.gray200

    .name
        @extend .body1
        margin-bottom: 2px

    .serial_number
        @extends .caption
