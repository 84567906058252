.container
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    margin-top: 10px
    color: white

.left_container
    display: flex
    align-items: center