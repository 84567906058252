@import '../shared/shared.styl'

.sidebar
    width: 48px
    flex-shrink: 0
    flex-grow: 0
    background-color: colors.gray100
    transition: width 200ms
    overflow: auto
    @media print
        display: none


.sidebar_hidden
    @extend .sidebar
    width: 0

.icon_wrapper
    position relative
    height: 48px
    width: 48px
    margin: 8px 0
    max-width: 100%
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden
    &::before
        content: ''
        position: absolute
        display: block
        width: 100%
        height: 100%
        background: colors.gray200
        opacity: 0
        z-index: 0
        transition: opacity 200ms

    &:hover::before
        opacity: 1
    
    &::after
        content: ''
        display: block
        height: 100%
        width: 2px
        position: absolute;
        top: 0
        right: 0
        z-index: 1
        background-color: colors.teal500
        opacity: 0
        transition: opacity 200ms


    img,svg
        flex-shrink: 0
        z-index: 1

.icon_wrapper_active
    border-right-color: 2px solid colors.teal500
    &::before
        opacity: 1
    &::after
        opacity: 1

.insights_container
    cursor pointer