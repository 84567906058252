@import '../../../shared/shared.styl'

.attachments_table
    :global
        .ant-table-header,
        .ant-table-body,
        .ant-table thead > tr > th
            background: colors.gray300

        .ant-table tbody > tr
            td,
            > td.ant-table-cell-fix-left,
            > td.ant-table-cell-fix-right
                background: colors.gray300

        .ant-table-filter-trigger-container:hover
        .ant-table-thead th.ant-table-column-has-sorters:hover
            background: colors.gray400

        .ant-table tbody > tr:hover
            td,
            > td.ant-table-cell-fix-left,
            > td.ant-table-cell-fix-right
                background: colors.gray400 !important

        .ant-table-cell-scrollbar
            box-shadow: 0 1px 0 1px colors.gray300
