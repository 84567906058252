@import '../../shared/shared.styl'

.plan_item
    @extend .body1
    display: flex
    align-items: flex-start
    padding: 4px 0
    break-inside: avoid
    svg
        color: colors.primary
        margin-right: 10px
        height: 20px
        flex-shrink: 0
    div
        text-align: initial


[data-mode="bright"]
    .plan_item
        color: colors.black
        svg
            color: colors.primary_bright